/*
 * @Description: 头部注释
 * @Author: v_Yangzhou
 * @Date: 2021-06-25 21:37:09
 * @LastEditors: v_binqin
 * @LastEditTime: 2022-11-27 16:21:50
 */
import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  //   meta: {
  //     title: '燃气监测'
  //   }
  // },
  {
    path: '/',
    name: 'newHome',
    component: () => import('../views/newHome.vue'),
    redirect: '/runTimeStatus',
    meta: {
      title: '首页'
    },
    children: [
      {
        path: '/runTimeStatus',
        name: 'runTimeStatus',
        component: () => import('../views/runTimeStatus.vue'),
        meta: {
          title: '运行状态'
        }
      },
      {
        path: '/alrmThing',
        name: 'alrmThing',
        component: () => import('../views/alrmThing.vue'),
        meta: {
          title: '报警事件'
        }
      },
      {
        path: '/realTimeData',
        name: 'realTimeData',
        component: () => import('../views/realTimeData.vue'),
        meta: {
          title: '实时数据'
        }
      },
      {
        path: '/mine',
        name: 'mine',
        component: () => import('../views/mine.vue'),
        meta: {
          title: '个人中心'
        }
      },
    ]
  },
  {
    path: '/microbreak',
    name: 'microbreak',
    component: () => import('../views/microbreak.vue'),
    meta: {
      title: '添加微断设备'
    }
  },
  {
    path: '/addEquipment',
    name: 'addEquipment',
    component: () => import('../views/addEquipment.vue'),
    meta: {
      title: '添加设备'
    }
  },
  {
    path: '/mine/deviceList',
    name: 'deviceList',
    component: () => import('../views/mine/deviceList.vue'),
    meta: {
      title: '设备列表'
    }
  },
  {
    path: '/mine/actionList',
    name: 'actionList',
    component: () => import('../views/mine/actionList.vue'),
    meta: {
      title: '开关记录'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/police',
    name: 'police',
    component: () => import('../views/police.vue'),
    meta: {
      title: '报警记录'
    }
  },
  {
    path: '/arm',
    name: 'arm',
    component: () => import('../views/arm.vue'),
    meta: {
      title: '机械臂日志'
    }
  },
  {
    path: '/reguser',
    name: 'reguser',
    component: () => import('../views/regUser.vue'),
    meta: {
      title: '用户注册'
    }
  },
  {
    path: '/updatePass',
    name: 'updatePass',
    component: () => import('../views/updatePass.vue'),
    meta: {
      title: '修改密码'
    }
  },

  {
    path: '/deviceList',
    name: 'deviceList',
    component: () => import('../views/deviceList.vue'),
    meta: {
      title: '设备列表'
    }
  },
  {
    path: '/deviceData',
    name: 'deviceData',
    component: () => import('../views/deviceData.vue'),
    meta: {
      title: '实时数据'
    }
  },

]

const router = new VueRouter({
  routes
})

export default router
